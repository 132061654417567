import React, { useState } from 'react';
import LeadInfoContext from '../../contexts/LeadInfoContext';

interface Props {}

const LeadInfo: React.FC<Props> = ({ children }) => {
  const [leadDataCollected, setLeadDataCollected] = useState(false);

  return (
    <LeadInfoContext.Provider
      value={{ leadDataCollected, setLeadDataCollected }}
    >
      {children}
    </LeadInfoContext.Provider>
  );
};

export default LeadInfo;
