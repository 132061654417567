import ProgressDashboard from '../images/icons/progress-dashboard.inline.svg';
import ReportGeneration from '../images/icons/report-generation.inline.svg';
import DocumentRepo from '../images/icons/document-repo.inline.svg';
import IssuesManagement from '../images/icons/issues-management.inline.svg';
import Expand from '../images/icons/expand.inline.svg';
import IncreaseRevenue from '../images/icons/increase-revenue.inline.svg';
import Visibility from '../images/icons/visibility.inline.svg';
import Referral from '../images/icons/referral.inline.svg';

export const SHORT_FEATURES = [
  'Progress',
  'Documents',
  'Drawings',
  'Site Photos',
  'Drone',
  'Site Issues',
];

export const PARTNER_FEATURES = [
  {
    icon: ProgressDashboard,
    title: 'Progress Dashboard',
    caption:
      'BOCS Dashboard acts a single source of truth for the project progress',
    image: require('../images/features/project-dashboard.png'),
  },
  {
    icon: ReportGeneration,
    title: 'Report Generation',
    caption:
      'Generate custom weekly, monthly and yearly report with just click of a button',
    image: require('../images/features/project-dashboard.png'),
  },
  {
    icon: DocumentRepo,
    title: 'Documents Repository',
    caption: '24/7 access to all the updated documents and drawings',
    image: require('../images/features/project-dashboard.png'),
  },
  {
    icon: IssuesManagement,
    title: 'Issues Management',
    caption:
      'Supervisors can raise issues directly and notify project managers',
    image: require('../images/features/project-dashboard.png'),
  },
];

export const VALUE_ADDITION = [
  {
    icon: Expand,
    title: 'Expand your value-added offerings',
  },
  {
    icon: IncreaseRevenue,
    title: 'Increase your revenue',
  },
  {
    icon: Visibility,
    title: 'Get more visibility of your brand',
  },
  {
    icon: Referral,
    title: 'Referral business from BOCS',
  },
];
