import React from 'react';
import cx from 'classnames';

interface Props {
  className?: string;
  style?: React.CSSProperties;
}

const Spinner: React.FC<Props> = ({ className, style }) => {
  return <div className={cx('loader', className)} style={style} />;
};

export default Spinner;
