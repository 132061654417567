import React from 'react';
import classNames from 'classnames';
import { ToastProps } from 'react-toast-notifications';

interface Props extends ToastProps {}

const Toast: React.FC<Props> = ({ children, appearance }) => {
  return (
    <div
      className={classNames(
        'px-4 py-2 rounded',
        appearance === 'success'
          ? 'bg-green-500 text-white'
          : appearance === 'error'
          ? 'bg-red-500 text-white'
          : 'bg-gray-900 text-gray-100',
      )}
    >
      <div className="text-xs text-center whitespace-no-wrap">{children}</div>
    </div>
  );
};

export default Toast;
