import React, { forwardRef } from 'react';
import classNames from 'classnames';

interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.HTMLProps<HTMLInputElement>,
      HTMLInputElement
    >,
    'ref'
  > {
  ref?: any;
  inputStyle?: React.CSSProperties;
  inputClassName?: string;
  error?: string;
}

const Input: React.FC<Props> = forwardRef(
  (
    {
      style,
      className,
      inputStyle,
      inputClassName,
      error,
      ...restProps
    }: Props,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    return (
      <div className={className} style={style}>
        <div
          className={classNames(
            'bg-gray-100 border rounded px-4 py-2',
            error ? 'border-red-500' : undefined,
          )}
        >
          <input
            className={classNames(
              'text-gray-800 text-sm bg-transparent w-full focus:outline-none',
              inputClassName,
            )}
            placeholder="Name"
            ref={ref}
            style={inputStyle}
            {...restProps}
          />
        </div>
        {error ? (
          <div className="mt-1 text-xs text-red-600 tracking-wide">{error}</div>
        ) : null}
      </div>
    );
  },
);

Input.displayName = 'Input';

export default Input;
