import React from 'react';
import { FaLinkedin, FaMedium, FaHandshake } from 'react-icons/fa';
import { MdEmail, MdPhone } from 'react-icons/md';

const Footer = () => {
  return (
    <div className="bg-gray-900 py-8">
      <div className="max-w-xs md:max-w-2xl lg:max-w-4xl flex -m-2 mx-auto">
        <div className="hidden md:block w-1/3 p-2 mb-4 md:mb-0">
          <a href="#features" className="text-sm mb-4 text-gray-100 block">
            Features
          </a>
          <a href="#products" className="text-sm mb-4 text-gray-100 block">
            Products
          </a>
          <a href="/partners" className="text-sm mb-4 text-gray-100 block">
            <span>Partners</span>
          </a>
          <a
            href="https://beta.bocs.work"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm text-gray-100 block"
          >
            Login
          </a>
        </div>
        <div className="w-1/2 md:w-1/3 p-0 md:p-2 text-left md:text-center mb-4 md:mb-0">
          <a
            href="https://www.linkedin.com/company/bocs-work/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm text-gray-100 block mb-4"
          >
            <FaLinkedin className="mr-2 inline" size={20} />
            <span>LinkedIn</span>
          </a>
          <a
            href="https://medium.com/@team_37693"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm text-gray-100 block mb-4"
          >
            <FaMedium className="mr-2 inline" size={20} />
            <span>Medium</span>
          </a>
          <a
            href="/partners"
            className="text-sm text-gray-100 block visible md:invisible"
          >
            <FaHandshake className="mr-2 inline" size={20} />
            <span>Partners</span>
          </a>
        </div>
        <div className="w-1/2 md:w-1/3 p-0 md:p-2 text-left md:text-right">
          <a
            href="mailto:team@bocs.work"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm text-gray-100 block mb-4"
          >
            <MdEmail className="mr-2 inline" size={20} />
            <span>team@bocs.work</span>
          </a>
          <div className="text-sm text-gray-100 mb-4">
            <MdPhone className="mr-2 inline" size={20} />
            <span>+917678539106 / +918687749723</span>
          </div>
          <div className="text-sm text-gray-100">
            602, Naurang House, 21 KG Marg, <br />
            Connaught Place, New Delhi - 110001, India
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
