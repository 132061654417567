import { createContext } from 'react';

const LeadInfoContext = createContext<{
  leadDataCollected: boolean;
  setLeadDataCollected: (dataCollected: boolean) => void;
}>({
  leadDataCollected: false,
  setLeadDataCollected: () => {},
});

export default LeadInfoContext;
