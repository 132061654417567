import React, { forwardRef } from 'react';
import cx from 'classnames';
import { IconBaseProps } from 'react-icons/lib/cjs';

import Spinner from '../Spinner';

interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    'children' | 'ref'
  > {
  label?: string;
  icon?: React.ComponentType<IconBaseProps>;
  loading?: boolean;
}

const Button: React.FC<Props> = forwardRef(
  (
    { label, icon: Icon, loading, disabled, className, ...restProps }: Props,
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    return (
      <button
        ref={ref}
        disabled={disabled || loading}
        className={cx(
          'px-4 h-8 rounded bg-gray-900 text-gray-300 cursor-pointer w-full focus:outline-none focus:shadow-outline',
          className,
        )}
        {...restProps}
      >
        <div className="flex items-center justify-center h-full w-full">
          {loading ? <Spinner className="mr-2" /> : null}
          {Icon && !loading ? <Icon size={16} className="mr-2" /> : null}
          <span className="text-xs font-semibold uppercase tracking-wider">
            {label}
          </span>
        </div>
      </button>
    );
  },
);

Button.displayName = 'Button';

export default Button;
