import React from 'react';

interface Props {
  type?: string;
}

const NavBar: React.FC<Props> = ({ type }) => {
  const showNav = type !== 'partner';
  return (
    <div className="fixed top-0 left-0 right-0 z-40 shadow-md bg-white">
      <div className="max-w-xs md:max-w-2xl lg:max-w-4xl mx-auto flex items-center h-12 md:h-16">
        <a href={'/'}>
          <img
            src={require('../../images/logo.svg')}
            alt="BOCS"
            className="h-6 mr-2 md:mr-4"
          />
        </a>
        <div className="text-lg md:text-xl text-gray-800 font-medium">BOCS</div>
        <div className="flex-1" />
        {showNav && (
          <a
            href="#features"
            className="hidden md:block text-sm text-gray-700 mr-6"
          >
            Features
          </a>
        )}
        {showNav && (
          <a
            href="#products"
            className="hidden md:block text-sm text-gray-700 mr-6"
          >
            Products
          </a>
        )}
        {showNav && (
          <a
            href="/partners"
            className="hidden md:block text-sm text-gray-700 mr-6"
          >
            Partners
          </a>
        )}
        <a
          href="https://beta.bocs.work"
          target="_blank"
          rel="noopener noreferrer"
          className="px-4 py-1 rounded border border-primary text-primary text-sm hover:bg-primary hover:text-white"
        >
          Login
        </a>
      </div>
    </div>
  );
};

export default NavBar;
