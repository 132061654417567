import React from 'react';
import { Helmet } from 'react-helmet';
import { ToastProvider } from 'react-toast-notifications';

import '../../styles/tailwind.css';
import LeadInfo from '../LeadInfo';
import Toast from '../Toast';
import Footer from '../Footer';

interface Props {
  children: JSX.Element | Array<JSX.Element>;
}

const Layout: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://assets.calendly.com/assets/external/widget.css"
          rel="stylesheet"
        />
        <script
          src="https://assets.calendly.com/assets/external/widget.js"
          type="text/javascript"
        ></script>
      </Helmet>
      <ToastProvider
        placement="bottom-center"
        components={{ Toast }}
        autoDismissTimeout={5000}
      >
        <LeadInfo>
          <div className="font-body antialiased overflow-hidden">
            {children}
          </div>
          <Footer />
        </LeadInfo>
      </ToastProvider>
    </>
  );
};

export default Layout;
