import React, { useCallback, useState, useContext, useEffect } from 'react';
import useForm from 'react-hook-form';
import validator from 'validator';
import { get, throttle } from 'lodash-es';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';

import Input from '../Input';
import Button from '../Button';
import LeadInfoContext from '../../contexts/LeadInfoContext';

interface Props {
  onSuccess?: () => void;
  onError?: (errorMessage: string) => void;
  initialValues?: {
    name?: string;
    organisationName?: string;
    email?: string;
    phone?: string;
  };
  className?: string;
  style?: React.CSSProperties;
  buttonLabel?: string;
  type?: string;
}

const LeadGenerationForm: React.FC<Props> = ({
  onError,
  onSuccess,
  initialValues,
  buttonLabel,
  type,
  className,
  style,
}) => {
  const { addToast } = useToasts();

  const { setLeadDataCollected } = useContext(LeadInfoContext);

  const { handleSubmit, register, errors, reset } = useForm({
    defaultValues: initialValues,
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(
    async (data: Record<string, string>) => {
      setLoading(true);
      try {
        const { name, organisationName, email, phone, message } = data;
        await axios.post('https://ms-api-staging.bocs.work/leads', {
          partner: type === 'partner',
          name,
          organisation: organisationName,
          email,
          phone,
          message,
        });
        reset();
        setLeadDataCollected(true);
        if (onSuccess) {
          onSuccess();
        }
        // @ts-ignore
        window.Calendly.initPopupWidget({
          url:
            'https://calendly.com/sonveersingh/30min/?hide_landing_page_details=1&hide_event_type_details=1',
          prefill: {
            name,
            email,
            customAnswers: {
              a1: organisationName || '',
              a2: message || '',
            },
          },
        });
      } catch (error) {
        const errorMessage = get(
          error,
          'response.data.message',
          'Something went wrong. Please try again',
        );
        if (onError) {
          onError(errorMessage);
        }
        addToast(errorMessage, { appearance: 'error', autoDismiss: true });
      } finally {
        setLoading(false);
      }
    },
    [addToast, onError, onSuccess, reset, setLeadDataCollected],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={className} style={style}>
      <div className="flex flex-wrap -m-2 items-center lg:mb-2">
        <div className="p-2 w-full lg:w-1/2">
          <Input
            placeholder="Name"
            name="name"
            ref={register({ required: true })}
            error={
              errors.name && errors.name.type === 'required'
                ? 'Name is required'
                : undefined
            }
          />
        </div>
        <div className="p-2 w-full lg:w-1/2">
          <Input
            placeholder="Organisation Name"
            name="organisationName"
            ref={register({ required: true })}
            error={
              errors.organisationName &&
              errors.organisationName.type === 'required'
                ? 'Organisation is required'
                : undefined
            }
          />
        </div>
      </div>
      <div className="flex flex-wrap -m-2 mt-2 lg:mt-0 items-center lg:mb-2">
        <div className="p-2 w-full lg:w-1/2">
          <Input
            placeholder="Email"
            name="email"
            ref={register({
              required: true,
              validate: (value: string) => validator.isEmail(value),
            })}
            error={
              errors.email
                ? errors.email.type === 'required'
                  ? 'Email is required'
                  : errors.email.type === 'validate'
                  ? 'Email is not valid'
                  : undefined
                : undefined
            }
          />
        </div>
        <div className="p-2 w-full lg:w-1/2">
          <Input
            placeholder="Phone"
            name="phone"
            ref={register({
              required: true,
            })}
            error={
              errors.phone && errors.phone.type === 'required'
                ? 'Phone is required'
                : undefined
            }
          />
        </div>
      </div>
      {type === 'partner' ? (
        <div className="flex -m-2  mt-2 lg:mt-0 items-center mb-2">
          <div className="p-2 w-full">
            <Input
              placeholder="Message"
              name="message"
              ref={register({
                required: false,
              })}
            />
          </div>
        </div>
      ) : (
        <div className="pt-4 lg:pt-0" />
      )}

      <Button
        type="submit"
        label={buttonLabel}
        loading={loading}
        className={type === 'partner' ? 'bg-blue-800 h-10' : 'h-10'}
      />
    </form>
  );
};

LeadGenerationForm.defaultProps = {
  buttonLabel: 'Book a Demo',
  type: 'user',
};

export default LeadGenerationForm;
